@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body, html{
    font-family: 'Poppins', sans-serif!important;
    overflow-x: hidden;
}
a:focus{
    box-shadow: none!important;
}